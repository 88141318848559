<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("address.title") }}</h4>
    </div>

    <!-- 未通过审核或未认证 -->
    <!-- <div class="no-authority" v-if="verified!=3">
        <p>KYC {{ $t('account.verify') }}: <el-tag effect="plain" hit :type="verifyState[$store.state.userVerified][0]">{{ verifyState[$store.state.userVerified][1] }}</el-tag></p>
        <p>{{$t('recharge_new.verified_tip')}}</p>
    </div> -->
    <!-- 未通过审核或未认证 -end -->

    <!-- 充值所有相关 -->
    <!-- v-if="verified==3" -->
    <div>
      <div v-loading="loading">
        <div class="p-20 border-bottom">
          <el-form
            ref="ruleForm"
            class="input-form mx-auto"
            :model="formData"
            :rules="rules"
          >
            <el-form-item>
              <div v-if="info.address.length > 0" class="d-flex-center">
                <el-button
                  v-for="(item, index) in info.address"
                  :key="index"
                  type="danger"
                  size="mini"
                  :plain="type !== index"
                  @click="typefn(index)"
                  >{{ item.tag ? item.tag.toUpperCase() : "" }}</el-button
                >
              </div>
            </el-form-item>

            <el-form-item prop="wallet_address">
              <el-input
                type="textarea"
                :rows="4"
                v-model="formData.wallet_address"
                :placeholder="$t('address.wallet_address')"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                class="w-100"
                native-type="button"
                @click="submitForm"
                >Submit</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div v-loading="loadingRecord">
        <div class="d-flex-center px-15 border-bottom">
          <h4 class="mr-auto color-333">{{$t('address.wallet_list_title')}}</h4>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            plain
            @click="fatchRecord()"
            >{{ $t("base.refresh") }}</el-button
          >
        </div>

        <div>
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="color-gray px-20 py-15 border-bottom font-14"
          >
            <el-col :span="4">{{$t('address.wallet_type')}}</el-col>
            <el-col :span="20">{{$t('address.wallet_address_title')}}</el-col>
            <el-col :span="4" style="text-align: right">{{$t('address.wallet_opea')}}</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in list"
            :key="index"
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="px-20 py-15 border-bottom font-14"
          >
            <el-col :span="4">{{
              item.type ? item.type.toUpperCase() : ""
            }}</el-col>
            <el-col :span="20" style="word-break: break-all">{{
              item.wallet_address
            }}</el-col>
            <el-col :span="4" style="text-align: right">
              <el-button
                type="danger"
                size="small"
                plain
                class="bg-t"
                @click="cancelAddress(item.id)"
                >{{$t('address.wallet_cancel')}}</el-button
              >
            </el-col>
          </el-row>
          <!-- <div class="d-flex-center pt-20">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :page-size="per_page"
              :current-page="current_page"
              :total="list.total"
              @current-change="onPage"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import { float1 as regexpFloat } from "@/services/regexp";
import XEClipboard from "xe-clipboard";
export default {
  data() {
    return {
      coinLogo,
      //   info: {
      //     open: false,
      //     auto: false,
      //     address: [
      //       {
      //         tag: "DEFAULT",
      //         address: "",
      //       },
      //     ],
      //   },
      info: {
        address: [
          {
            tag: "erc20",
            address: "",
          },
          {
            tag: "omni",
            address: "",
          },
          {
            tag: "trc20",
            address: "",
          },
        ],
      },
      type: 0,
      tips: this.$t("recharge.tips"),
      loadingData: true,
      loadingRecord: false,
      loading: false,
      formData: {
        coin: this.$route.params.coin,
        wallet_address: null,
      },
      query: { ...this.$route.query },
      list: [],
      state: [
        ["primary", "Checking"],
        ["success", "Success"],
        ["danger", "Fail"],
        ["info", "Cancel"],
      ],
      timer: null,
      rules: {
        wallet_address: [
          { required: true, message: this.$t('address.wallet_err'), trigger: "blur" },
        ],
      },

      // 新增
      verified: "",
      verifyState: [
        ["info", this.$t("account.verify_state.unsubmitted")],
        ["danger", this.$t("account.verify_state.fail")],
        ["primary", this.$t("account.verify_state.checking")],
        ["success", this.$t("account.verify_state.success")],
      ],
      lazy: true,
      srcList: [],
      list_img: [],
      current_page: 1,
      per_page: 5,

      balance: "",

      options: [],
      select_value: 0,
    };
  },
  watch: {
    $route: function (to, from) {
      if (to.params.coin === from.params.coin) return;
      this.fatchData();
      // this.fatchRecord()

      //新增
      this.getVerify();
      this.getAccountBalance();
    },
  },
  created: function () {
    this.fatchData();
    // this.fatchRecord()

    //新增
    this.getVerify();
    this.getAccountBalance();
  },
  methods: {
    //获取钱包
    getAccountBalance: async function () {
      const { data } = await this.$request.post("v1/symbol/getAccountBalance", {
        coin: "usdt",
        pair: "usdt",
      });
      this.balance = data.balance.balance_coin;
    },

    //获取实名认证信息
    getVerify: async function () {
      const { data } = await this.$request.post("v1/user/info");
      this.$store.commit("userEmail", data.email);
      this.$store.commit("userId", data.id);
      this.$store.commit("userVerified", data.verified);
      this.verified = data.verified;
    },

    //获取钱包地址及其分类
    fatchData: async function () {
      const { data } = await this.$request.post("v1/order/getWalletAddress",{wallet_type:1});
      this.info.address.forEach(function (item, index) {
        item.address = data[item.tag];
      });
      this.fatchRecord();
    },

    submitForm: async function () {
      try {
        await this.$refs.ruleForm.validate();

        if (this.list.length >= 10) {
          return this.$message({
            message: this.$t('address.wallet_tip'),
            type: "error",
          });
        }

        this.loading = true;

        let that = this;
        let $data = await this.$request.post("v1/user/addWalletAddress", {
          wallet_address: this.formData.wallet_address,
          type: this.info.address[this.type].tag,
        });
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.formData.wallet_address = null;
            that.fatchRecord();
            that.loading = false;
          },
        });
      } catch (error) {
        this.loading = false;
      }
    },

    //提币记录
    fatchRecord: async function () {
      this.loadingRecord = true;
      try {
        const { data } = await this.$request.post(
          "v1/user/getWalletAddressList"
        );
        this.list = data;
        this.loadingRecord = false;
      } catch (error) {
        this.loadingRecord = false;
      }
    },

    //cancelAddress取消当前冷钱包
    cancelAddress: async function (e) {
      this.loadingRecord = true;
      let that = this;
      let $data = await this.$request.post("v1/user/delWalletAddress", {
        id: e,
      });
      this.$message({
        showClose: true,
        type: "success",
        duration: 2000,
        message: $data.message,
        onClose: function () {
          that.fatchRecord();
        },
      });
    },

    //切换钱包类型
    typefn(index) {
      this.type = index;
    },

    onPage(page) {
      //   this.query.page = page || 1;
      //   this.onSearch();
      this.current_page = page;
      this.fatchRecord();
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 360px;
  height: 270px;
}
.no-authority {
  padding: 120px 0;
  box-sizing: border-box;
  text-align: center;
}
.top-text {
  justify-content: center;
  display: flex;
  align-items: center;
}
.top-text span {
  color: #999;
}
.top-text strong {
  font-size: 20px;
  font-weight: bold;
}
</style>